import React from 'react';
import Heading from '../components/Heading';
import ChapterLinks from '../components/ChapterLinks';
import Sidebar from '../components/Layout/Sidebar';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Accordian from '../components/Markdown/Accordian';
import Alert from '../components/Markdown/Alert';
import Tooltip from '../components/Markdown/Tooltip';

const Page = ({ pageContext, data }) => {
    const page = data.strapiPage;
    const relatedPages = data.relatedStrapiPages

    const ukStyles = {
        ul: (props) => (<ul {...props} class="uk-list" />),
        dl: (props) => (<dl {...props} class="uk-description-list" />),
        table: (props) => (<table {...props} class="uk-table" />),
        Accordian,
        Alert,
        Tooltip,
    }
    return (
        <>
            <Heading title={page.title} />
            <div className='uk-width-1-5 uk-visible@m uk-margin-left'>
                <ChapterLinks mdx={page.children[0].childMdx.body} />
            </div>

            <section className='uk-width-expand'>
                <MDXProvider components={ukStyles}>
                    <MDXRenderer>{page.children[0].childMdx.body}</MDXRenderer>
                </MDXProvider>
            </section>

            <Sidebar  pageData={page} relatedPageData={relatedPages} />
            
        </>
    );
};

export default Page;

export const pageQuery = graphql`
    query PageBySlug($pageSlug: String, $pageID: String) {
        strapiPage(slug: { eq: $pageSlug }) {
            id
            slug
            body
            title
            children {
                ... on ContentMDX {
                    childMdx {
                        body
                    }
                }
            }
        }

        relatedStrapiPages (parent: {id: {eq: $pageID}}) {
            posts {
                title
                slug
            }
        }
    }
`;
